import React = require("react");
import styled from "styled-components";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Grid, Label, Icon } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { Form } from "semantic-ui-react";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { composeValidators, mustBeNumber, mustBeValidEmail, required } from "common/helpers/finalForm";
import { COLORS } from "component/UI/common";
import { Button } from "component/UI/Button";
import { checkUserEmail, CheckUserEmailResponse } from "common/helpers/user";
import { FORM_ERROR, FormApi } from "final-form";
import { ObjectAny } from "common/helpers/types";
import Store from "common/store";
import EditPicture from "component/popup/editPicture";
import { Avatar } from "component/Avatar";
import { StyleSettingsGrid, StyleSettingsGridRow } from "./settings.style";
import { StyleLabel, StyleSmallText, StyleTerms } from "common/styledComponents/signUpForm.style";
import { $opensans } from "common/styledComponents/_config.style";
import { getMixpanel, database } from "common/api";
import { cookies, ls } from "common/helpers/storage";
import { StyleConfirm } from "component/Explore/includes/NewExplore.style";

const StylePlanTag = styled(Label)`
  background-color: ${COLORS.aqua500} !important;
  color: #fff !important;
  font-size: 14px !important;
`;

const StyleText = styled.div`
  font-family: ${$opensans};
  font-size: 15px;
  margin-bottom: 24px;
`;

interface Props {
  store?: Store;
}

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

const capitaliseFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

const Component = (props: Props): JSX.Element => {
  const store = props.store!;
  const { user, group_types, token } = store;
  const initialValues: FormValues = {
    first_name: user!.first_name,
    last_name: user!.last_name,
    email: user!.email,
    phone: user!.phone,
  };
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const onSaveUserDetails = async (values: FormValues, form: FormApi<FormValues, FormValues>): Promise<any> => {
    setShowConfirmModal(false);
    // Get updated field values
    const body: Partial<FormValues> = {};
    const { dirtyFields } = form.getState();
    Object.keys((dirtyFields as ObjectAny) || {}).forEach((key) => {
      if (dirtyFields[key]) {
        body[key] = values[key];
      }
    });
    if (Object.keys(body).length) {
      getMixpanel(store).track("Settings > Account: Update details", { ...body });
      // Check if email already exists
      if (body.email) {
        const checkUserRes: CheckUserEmailResponse = await checkUserEmail(body.email);
        if (checkUserRes.userExists) {
          return { [FORM_ERROR]: "This email address is already registered. Please use another email." };
        }
      }
      const res: any = await database.put(`users/${user!.id}`, body, token!);
      if (res.statusCode === 200) {
        if (body.email) {
          // Replace token if email changed
          const { JWTTOKEN } = res.body.data;
          store.token = JWTTOKEN;
          ls.setItem("token", JWTTOKEN);
          cookies.set("token", JWTTOKEN, { path: "/", sameSite: "none", secure: true });
        }
        store.user = { ...store.user, ...res.body.data.user };
        form.initialize(values);
      } else {
        return { [FORM_ERROR]: "Error. Please try again later." };
      }
    }
  };

  const showPlanModalClickHandler = () => {
    store.handleUpgradeShow(true);
    getMixpanel(store).track("Settings > Account: Change Plan");
  };

  React.useEffect(() => {
    getMixpanel(store).track("Page view", { Page: "Settings > Account" });
  }, []);

  return (
    <>
      <StyleSettingsGrid>
        <StyleSettingsGridRow columns={2}>
          <Grid.Column>
            <StyleLabel>Profile</StyleLabel>
            <Avatar name={`${user!.first_name} ${user!.last_name}`} image={user!.avatar} size={80} />
            <EditPicture store={store} type="avatar" />
          </Grid.Column>
          <Grid.Column>
            <StyleLabel>Organisation</StyleLabel>
            <StyleText>{user!.group.name}</StyleText>
            <StyleLabel>Plan</StyleLabel>
            <StylePlanTag>
              <Icon name="gem" className="text-white" />
              {group_types.map((group_type) => {
                if (group_type.id === user!.group.group_type_id) {
                  return group_type.name;
                }
              })}
            </StylePlanTag>
            <StyleSmallText>
              Discover other Seer Data Plans and options
              <StyleTerms onClick={showPlanModalClickHandler}> here</StyleTerms>
            </StyleSmallText>
          </Grid.Column>
        </StyleSettingsGridRow>
      </StyleSettingsGrid>
      <FinalForm
        onSubmit={onSaveUserDetails}
        initialValues={initialValues}
        render={(formRenderProps) => {
          const { handleSubmit, dirtyFields, form, values, submitting, submitError, hasValidationErrors, pristine } = formRenderProps;
          // Capitalise the first letter of first name and last name
          Object.keys(dirtyFields).forEach((field) => {
            if ((field === "first_name" || field === "last_name") && dirtyFields[field]) {
              form.change(field, capitaliseFirstLetter(values[field]));
            }
          });
          return (
            <>
              <StyleConfirm
                open={showConfirmModal}
                header="Confirm your edits"
                content="Are you sure you want to update your details?"
                confirmButton="Yes"
                onCancel={() => setShowConfirmModal(false)}
                onConfirm={handleSubmit}
              />
              <Form className="mt-4 mb-3">
                <div className="d-flex flex-column flex-md-row" style={{ gap: 20 }}>
                  <Field
                    label="First Name"
                    name="first_name"
                    aria-label="First name"
                    className="w-100 w-md-50"
                    component={FieldInput}
                    validate={composeValidators(required)}
                  />
                  <Field
                    label="Last Name"
                    name="last_name"
                    aria-label="Last name"
                    className="mb-3 w-100 w-md-50"
                    component={FieldInput}
                    validate={composeValidators(required)}
                  />
                </div>
                <Field
                  label="Email"
                  name="email"
                  aria-labelledby="settingsModalAccountEmailLabel"
                  component={FieldInput}
                  validate={composeValidators(required, mustBeValidEmail)}
                />
                <Field
                  label="Phone"
                  name="phone"
                  aria-labelledby="settingsModalAccountPhoneLabel"
                  component={FieldInput}
                  validate={composeValidators(required, mustBeNumber)}
                />
              </Form>
              <Button
                colorConfig="redInverted"
                disabled={submitting || hasValidationErrors || pristine}
                onClick={() => setShowConfirmModal(true)}
              >
                Save
                <Icon name="save" className="ml-2 mr-0" />
              </Button>
              {submitError && (
                <p role="alert" className="fw-700 fs-1125 mt-2" style={{ color: COLORS.red500 }}>
                  {submitError}
                </p>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export const Account = inject("store")(observer(Component));
