import styled from "styled-components";
import { Dropdown, Progress, Tab } from "semantic-ui-react";
import { COLORS } from "component/UI/common";
import SimpleBarReact from "simplebar-react";

export const StyledTab = styled(Tab)<{ fullscreen?: boolean }>`
  .ui.menu {
    font-family: "Open Sans";
    font-size: 16px !important;
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  a.item {
    color: ${COLORS.grey600} !important;
    font-weight: 700 !important;
    border-color: ${COLORS.grey300} !important;
    display: flex !important;
    justify-content: center;
    transition: 0.5s !important;
    width: 50%;
    &:hover {
      border-color: ${COLORS.indigo600} !important;
    }
  }
  a.active {
    color: ${COLORS.indigo600} !important;
  }
  .ui.secondary.pointing.menu .active.item {
    border-color: ${COLORS.indigo600} !important;
  }
  width: ${(props) => (props.fullscreen ? "800px" : "100%")};
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ActionCard = styled.div<{ showleftborder?: boolean }>`
  background-color: #ffffff;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  &:hover {
    box-shadow:
      1px 1px 5px 0px rgba(33, 33, 33, 0.1),
      -1px -1px 5px 0px rgba(33, 33, 33, 0.1);
  }
  border-left: ${(props) => (props.showleftborder ? `6px solid ${COLORS.blue}` : "none")};
  padding-left: ${(props) => (props.showleftborder ? "calc(1.5rem - 6px)" : "1.5rem")};
`;

export const TextButton = styled.p<{ color: string; hoverColor?: string }>`
  font-family: "Open Sans";
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.color};
  :hover {
    color: ${(props) => props.hoverColor || props.color};
  }
`;

export const StyledProgress = styled(Progress)<{ foreground: string; background: string }>`
  &.ui.progress {
    border-radius: 6px;
    height: 8px;
    margin-bottom: 0px;
    div.bar {
      border-radius: 6px;
      height: 8px;
      background-color: ${(props) => props.foreground} !important
      opacity: ${(props) => (props.percent ? 1 : 0)}
    }
    background-color: ${(props) => props.background}
  }
`;

export const TaskInput = styled.input`
  flex-grow: 1;
  font-size: 14px;
  color: ${COLORS.grey900}
  margin-right: 1rem;
  padding-top: 2px;
  border: none;
  border-bottom: 1px solid ${COLORS.grey600}
  :focus {
    border-bottom: 1px solid ${COLORS.blue}
  }
`;

export const ActionInput = styled.input`
  border: none !important;
  width: 100%;
  background-color: ${COLORS.indigo100} !important;
  border-bottom: 1px solid ${COLORS.indigo100} !important;
  padding: 8px !important;
  :focus {
    border-bottom: 1px solid ${COLORS.blue} !important;
  }
  ::placeholder {
    color: ${COLORS.grey600} !important;
  }
`;

export const ActionInputSecondary = styled.input`
  background-color: #ffffff !important;
  border: 1px solid ${COLORS.indigo200} !important;
  padding: 8px !important;
  ::placeholder {
    color: ${COLORS.grey600} !important;
  }
`;

export const ActionTextarea = styled.textarea`
  border: none !important;
  width: 100%;
  height: 76px !important;
  padding: 8px !important;
  background-color: #ffffff !important;
  border: 1px solid ${COLORS.indigo200} !important;
  :focus {
    border-bottom: 1px solid ${COLORS.blue} !important;
  }
  ::placeholder {
    color: ${COLORS.grey600} !important;
  }
`;

export const ConversationsSideBarContentWrapper = styled(SimpleBarReact)`
  height: 100%;
`;

export const ActionsSideBarContentWrapper = styled(SimpleBarReact)`
  height: 100%;
  padding: 5px;
`;

export const CollaboratorDropdown = styled(Dropdown.Menu)`
  box-sizing: border-box;
  min-width: 200px !important;
`;

export const CollaboratorDropdownListContainer = styled(SimpleBarReact)`
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  max-height: 200px;
`;
