import * as React from "react";
import { useRef } from "react";
import styled from "styled-components";
import { RichMentionsProvider } from "react-rich-mentions";
import { RichMentionsInput } from "./third-party-library-overrides/react-rich-mentions/RichMentionsInput";
import { RichMentionsAutocomplete } from "./third-party-library-overrides/react-rich-mentions/RichMentionsAutocomplete";
import { COLORS } from "./UI/common";
import { Button, Icon } from "semantic-ui-react";

// The function that will search for autocomplete result.
// The argument is the searchable text (for example '@test').
// It can return a promise. The result have to contains for each item:
// - a prop "ref" -> let say `<@vince|U23872783>`
// - a prop "name" -> the display name
const getOnMentionCallback = (options: any) => async (text: string) => {
  const query = text.substr(1).toLowerCase(); // remove the '@'
  return options.filter((item) => item.ref.toLowerCase().includes(query));
};

// Called to customize visual elements inside input.
// Can be used to add classes, aria, ...
// `final` is a boolean to know if the fragment is resolved still
// waiting for user to select an entry in autocomplete
const getCustomizeFragment = () => (span: HTMLSpanElement, final) => {
  span.className = final ? "final" : "pending";
  span.style.cssText = `
      font-weight: 600;
      color: ${COLORS.red500};
      background-color: #f0f0f0;
      padding: 2px 3px;
      margin: 125px 0px;
      border-radius: 3px;
      overflow-wrap: normal;  // Don't wrap tags across lines
    `;
  // console.log(span.innerHTML);
  // const image = new Image();
  // image.src = "https://seer-media.s3.amazonaws.com/images/d1058378-ed4d-4584-be4e-505b43add81b.jpeg";
  // span.prepend(image);
  // console.log(span.innerHTML);
};

const configs = {
  // The fragment to transform to readable element.
  // For example, slack is using `<[name]|[id]>` -> `<vince|U82737823>`
  // At Seer, we are using `<[type]:[id]|[displayName]>` -> `coment <user:235|Kristi Mansfield> cpmment`
  match: /<([^:|]+?):([^:|]+?)\|([^>]+?)>/g,

  // Use it in combination with .match to choose what to display to your user instead of the fragment
  // Given the regex `/<(@\w+)\|([^>]+)>/g` and the fragment `<vince|U82737823>`
  // - $& -> <vince|U82737823>
  // - $1 -> vince
  // - $2 -> U82737823
  matchDisplay: "$3",

  // The query that will start the autocomplete
  // In this case it will match:
  // - @
  // - @test
  // _ @test_
  // Can be changed to catch spaces or some special characters.
  query: /@([a-zA-Z0-9_-]+)?/,
};

export const RichMentionEditor = (props) => {
  const { save, saveButtonText, defaultValue, editable, cancel, clearOnSave, mentionUsers, mentionOrgs } = props;
  const ref = useRef();

  const mentionDropdownOptions = [
    ...(mentionUsers
      ? mentionUsers.map((user) => ({
          ref: `<user:${user.id}|${user.first_name} ${user.last_name}>`,
          name: `${user.first_name} ${user.last_name}`,
          avatar: user.avatar ?? "/assets/default.png",
          type: "User",
        }))
      : []),
    ...(mentionOrgs
      ? mentionOrgs.map((org) => ({
          ref: `<group:${org.id}|${org.name}>`,
          name: `${org.name}`,
          avatar: org.logo ?? "/assets/defaultGroup.png",
          type: "Organisation users with access",
        }))
      : []),
    {
      ref: "<access:all|Everyone>",
      name: "Everyone",
      avatar: "/assets/default.png",
      type: "All users with access",
    },
  ];

  const editorConfigWithCallbacks = [
    {
      ...configs,
      onMention: getOnMentionCallback(mentionDropdownOptions),
      customizeFragment: getCustomizeFragment(),
    },
  ];

  const clearInputArea = () => {
    const currentRef: any = ref.current;
    currentRef.setValue("");
  };
  const onSubmit = () => {
    const currentRef: any = ref.current;
    const commentText = currentRef?.getTransformedValue();
    if (commentText.length > 0) {
      save(commentText);
      if (clearOnSave) {
        clearInputArea();
      }
    }
  };

  return (
    <StyledRichMentionsEditorContainer>
      <RichMentionsProvider configs={editorConfigWithCallbacks} getContext={ref}>
        <StyledRichMentionEditor
          defaultValue={defaultValue ?? ""}
          style={{
            minHeight: "100px",
          }}
          contentEditable={editable ?? true} // Assume content is editable when no value is passed
        />
        <RichMentionsAutocomplete fixed={false} />
        {editable && save && (
          <StyledButtonContainer>
            <Button color="purple" onClick={onSubmit}>
              {saveButtonText ?? "Save"} <Icon name="comment" className="ml-2 mr-0" />
            </Button>
            {cancel && (
              <Button onClick={cancel}>
                Cancel <Icon name="times" className="ml-2 mr-0" />
              </Button>
            )}
          </StyledButtonContainer>
        )}
      </RichMentionsProvider>
    </StyledRichMentionsEditorContainer>
  );
};

/* Styles */

export const StyledRichMentionsEditorContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  @media (min-width: 992px) {
    min-width: 500px;
  }

  .autocomplete {
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    background-color: #fcfcfc;
  }

  .autocomplete-list {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 600;
  }

  .autocomplete-item {
    background-color: unset;
    padding: 5px 10px;
    color: #333333;
    font-weight: 600;
    text-align: left;
    border: none;

    &:not(:last-child) {
      border-bottom: 1px solid #c3c3c3;
    }
  }

  .autocomplete-item.autocomplete-item-selected {
    color: ${COLORS.red500};
  }
`;

const StyledButtonContainer = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 5px;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: row;
    margin-left: 0px;
    margin-top: 10px;
  }
`;

export const StyledRichMentionEditor = styled(RichMentionsInput)`
  min-height: 100px;
  min-width: 70%;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px 0px;
  line-height: 2.2em;
  overflow: auto;
  overflow-wrap: break-word;
  white-space: pre-wrap; // Render line breaks

  ${({ contentEditable }) =>
    contentEditable &&
    `
    background-color: #fcfcfc;
    border: 1px solid #C3C3C3;
    padding: 10px;
    margin-top: 5px;
  `}
`;
