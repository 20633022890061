import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { Form as FinalForm, Field } from "react-final-form";
import { Button, Container, Form } from "semantic-ui-react";
import { FieldInput } from "component/FinalFormFields/FieldInput";
import { composeValidators, required, validPassword, valuesMustMatch } from "common/helpers/finalForm";
import * as qs from "qs";
import { useLocation } from "react-router-dom";
import { database, getMixpanel } from "common/api";
import { FORM_ERROR } from "final-form";
import Store from "common/store";

interface Props {
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const store = props.store!;
  const location = useLocation();
  const { search } = location;
  const { token, id } = qs.parse(search.slice(1) || "");

  const onSubmit = async (values) => {
    getMixpanel(store).track("Reset password", { "User ID": id });
    const res: any = await database.put(`users/${id}/password`, {
      password: values.password,
      token,
    });
    if (res.statusCode === 200) {
      store.logout();
      alert("Your password has been reset successfully! Please log in using your new password.");
      window.location.href = "/login";
      return;
    } else if (res.statusCode === 404) {
      return { [FORM_ERROR]: "Reset password failed. Please verify the URL and try again." };
    } else {
      return { [FORM_ERROR]: "Server error. Please try again later." };
    }
  };

  React.useEffect(() => {
    getMixpanel(store).track("Page view", {
      Page: "Reset password",
      "User ID": id,
    });
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Reset password</title>
      </Helmet>
      <h3 className="text-secondary">Reset password</h3>
      <FinalForm
        onSubmit={onSubmit}
        render={(formRenderProps) => {
          const { handleSubmit, submitError } = formRenderProps;
          return (
            <Form onSubmit={handleSubmit}>
              <Field
                name="password"
                label="New password"
                type="password"
                component={FieldInput}
                validate={composeValidators(required, validPassword)}
              />
              <Field
                name="confirm_password"
                label="Confirm password"
                type="password"
                component={FieldInput}
                validate={composeValidators(required, valuesMustMatch("password", "Passwords do not match"))}
              />
              <Button type="submit">Reset</Button>
              {submitError && <p className="text-danger fs-0875 mt-1 mb-0">{submitError}</p>}
            </Form>
          );
        }}
      />
    </Container>
  );
};

export const ResetPassword = inject("store")(observer(Component));
