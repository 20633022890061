import * as React from "react";
import { uniq } from "lodash";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Button, Checkbox, Icon, Modal } from "semantic-ui-react";
import { LocaliseSuitcaseInsightsTable } from "./includes/LocaliseSuitcaseInsightsTable";
import { VariableAndCategoryDropdown } from "./includes/VariableAndCategoryDropdown";
import { getAccessLevel } from "common/helpers/suitcases";
import { database } from "common/api";

interface ILocaliseSuitcaseModal {
  isOpen: boolean;
  closeClickHandler: () => void;
  confirmClickHandler: (insightIDs, localisations, isDuplicateSuitcase) => void;
  store?: any;
  suitcase?: any;
}

interface ILocalisation {
  variable: string;
  category: string[] | number[]; // number in some weird format datasets like maranguka
  replaceCategory?: string;
}

const LocaliseSuitcaseModalComponent = ({
  isOpen,
  closeClickHandler,
  confirmClickHandler,
  store,
  suitcase,
}: ILocaliseSuitcaseModal): JSX.Element => {
  const [showVariableLoader, setShowVariableLoader] = React.useState(false);
  const [showEmptyDropdown, setShowEmptyDropdown] = React.useState(true); // Empty dropdown for adding new variable/category
  const [insightsDimensions, setInsightsDimensions] = React.useState<any>({}); // All selected variables and categories of the suitcase. e.g. {var1: [cat1, cat2], var2: [cat1, cat2, cat3]}
  const [insightVariables, setInsightVariables] = React.useState<any>({}); // same as insightsDimensions but split by insight id as key { "some_insight_id": { ... } }
  const [selectedInsightIDs, setSelectedInsightIDs] = React.useState<number[]>([]);
  const [localisations, setLocalisations] = React.useState<ILocalisation[]>([]);
  const [isDuplicateSuitcase, setIsDuplicateSuitcase] = React.useState(true);
  const [insights, setInsights] = React.useState<any>([]);

  const insightIDs = insights.map((insight) => insight.id);
  // "localisations" can't be an empty array and each item's "variable" and "category" must exist
  const isLocalisationsValid = localisations.length > 0 && localisations.every((item) => item.variable && item.category?.length);
  const isLocaliseButtonDisabled = selectedInsightIDs.length === 0 || !isLocalisationsValid;
  // Set variable options "isDisabled" true if the option has been selected (exists in "localisation")
  const variableOptions = Object.keys(insightsDimensions).map((variable) => ({
    key: variable,
    label: variable,
    value: variable,
    isDisabled: localisations.map((item) => item.variable).indexOf(variable) >= 0,
  }));

  // If the user doesn't have "owner" or "full access". "Duplicate" checkbox should always be "true" and "disabled"
  let isOwnerOrFullAccess;
  if (store.suitcase.active && store.user) {
    isOwnerOrFullAccess = ["Owner", "Full Access"].includes(getAccessLevel(store.suitcase.active, store.user.id) || "");
  }

  // Select/Deselect all insights click handler
  const selectAllChangeHandler = (isAllInsightSelected: boolean) => {
    if (isAllInsightSelected) {
      setSelectedInsightIDs([]);
    } else {
      setSelectedInsightIDs(insightIDs);
    }
  };

  // Select/Deselect every single insight checkbox change handler
  const checkboxChangeHandler = (id) => {
    const findInsightIndex = selectedInsightIDs.indexOf(id);
    if (findInsightIndex < 0) {
      setSelectedInsightIDs([...selectedInsightIDs, id]);
    } else {
      // Remove from the list
      selectedInsightIDs.splice(findInsightIndex, 1);
      setSelectedInsightIDs([...selectedInsightIDs]);
    }
  };

  // Handler for "add/update/remove" items in "localisations"
  const changeLocalisationsHandler = (data) => {
    const { oldVariable, variable, category, replaceCategory, remove } = data;
    const copyLocalisations = [...localisations];
    const findVariableIndex = copyLocalisations.findIndex((item) => item.variable === variable);
    if (oldVariable) {
      // Replace "variable"
      const findOldVariableIndex = copyLocalisations.findIndex((item) => item.variable === oldVariable);
      copyLocalisations[findOldVariableIndex] = {
        variable,
        category: [],
      };
    } else if (findVariableIndex >= 0) {
      if (remove) {
        // Remove "variable"
        copyLocalisations.splice(findVariableIndex, 1);
      } else {
        if (category) {
          // Update "category"
          copyLocalisations[findVariableIndex].category = category;
        }
        if (replaceCategory) {
          // Update "replaceCategory"
          copyLocalisations[findVariableIndex].replaceCategory = replaceCategory;
        } else {
          // Remove "replaceCategory"
          delete copyLocalisations[findVariableIndex].replaceCategory;
        }
      }
    } else {
      // Add "variable"
      copyLocalisations.push({
        variable,
        category: [],
      });
      setShowEmptyDropdown(false);
    }
    setLocalisations(copyLocalisations);
  };

  const reloadInsightData = async () => {
    setShowVariableLoader(true);
    const rootSuitcaseId = suitcase.active.id;
    const res: any = await database.get(
      `suitcases/${rootSuitcaseId}/insights-recursive?exclude_columns=versions,keywords,subheading,banner,code,key`,
      "",
      store.token,
    );
    const resInsights = res?.body?.data?.insights || [];
    resInsights.sort((a, b) => a.name.localeCompare(b.name));

    // compile selected dimensions
    const nextInsightsDimensions: any = {};
    const nextInsightVariables: any = {};
    resInsights.forEach((insight) => {
      const insightJson = JSON.parse(insight.json);
      const nextVariables: any = {};
      insightJson.tables
        .filter((table) => table.type === "result")
        .forEach((table) => {
          const { columns, rows, filters } = table;
          const allDims = [...(columns || []), ...(rows || []), ...(filters || [])];
          for (const dim of allDims) {
            nextVariables[dim.dimension] = uniq([...(nextVariables[dim.dimension] || []), ...dim.values]);
            nextInsightsDimensions[dim.dimension] = uniq([...(nextInsightsDimensions[dim.dimension] || []), ...dim.values]);
          }
        });
      nextInsightVariables[insight.id] = nextVariables;
    });
    setInsights(resInsights);
    setInsightsDimensions(nextInsightsDimensions);
    setInsightVariables(nextInsightVariables);
    setShowVariableLoader(false);
  };

  React.useEffect(() => {
    if (isOpen) {
      reloadInsightData();
    }
    setShowEmptyDropdown(true);
    setSelectedInsightIDs([]);
    setLocalisations([]);
    setIsDuplicateSuitcase(true);
  }, [isOpen]);

  // Always show empty select variable/category dropdown if "localisations" is an empty array
  React.useEffect(() => {
    if (localisations.length === 0) {
      setShowEmptyDropdown(true);
    }
  }, [localisations]);

  return (
    <Modal open={isOpen} onClose={closeClickHandler}>
      <Modal.Header className="fs-2500 fw-700 text-secondary">Suitcase localisation</Modal.Header>
      <Modal.Content>
        {showVariableLoader ? (
          <div className="my-3 text-muted">
            <Icon name="cog" className="loading" />
            Loading variables for all insights...
          </div>
        ) : (
          <div className="mb-5">
            <p className="fs-1250 text-dark mb-2">
              Please note localisation refers to charts and tables only and summaries will need to be manually edited.
            </p>
            <p className="fs-1250 fw-700 text-secondary mb-2">
              Pick out the variable/s you wish to localise along with the category to localise to.
            </p>
            {/* Display a list of all "localisations" that has been set */}
            {localisations.map((item) => (
              <VariableAndCategoryDropdown
                key={item.variable}
                variableOptions={variableOptions}
                insightsDimensions={insightsDimensions}
                initialValue={Object.keys(item).length > 0 ? item : null}
                changeLocalisationsHandler={changeLocalisationsHandler}
              />
            ))}
            {/* Empty variable/category selection dropdowns for adding new variable/category */}
            {showEmptyDropdown && (
              <VariableAndCategoryDropdown
                variableOptions={variableOptions}
                insightsDimensions={insightsDimensions}
                changeLocalisationsHandler={changeLocalisationsHandler}
              />
            )}
            {localisations.length > 0 && (
              <Button className="bg-primary text-white bg-hover-red" size="tiny" onClick={() => setShowEmptyDropdown(true)}>
                Add another localisation variable <Icon name="plus" className="ml-1 mr-0" />
              </Button>
            )}
          </div>
        )}
        <div className="d-flex align-items-start mb-5">
          <Checkbox
            disabled={!isOwnerOrFullAccess}
            className="mr-2"
            checked={isDuplicateSuitcase}
            onChange={(_e, data) => setIsDuplicateSuitcase(data.checked!)}
          />
          <div>
            <p className="mb-1 text-secondary fs-1000 fw-700">Duplicate insights to new suitcase</p>
            <p className="text-muted">Uncheck to localise insights in place for Suitcases you have full access to</p>
          </div>
        </div>
        {/* All insights table */}
        <LocaliseSuitcaseInsightsTable
          insights={insights.map((insight) => ({
            ...insight,
            insightVariables: insightVariables[insight.id],
          }))}
          selectedInsightIDs={selectedInsightIDs}
          selectAllChangeHandler={selectAllChangeHandler}
          checkboxChangeHandler={(insightID) => checkboxChangeHandler(insightID)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button className="mr-2" onClick={closeClickHandler}>
          Cancel
        </Button>
        <Button
          color="purple"
          disabled={isLocaliseButtonDisabled}
          onClick={() => confirmClickHandler(selectedInsightIDs, localisations, isDuplicateSuitcase)}
        >
          Localise
          <Icon name="map marker alternate" className="ml-1 mr-0" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const LocaliseSuitcaseModal = inject((stores: any) => ({
  store: stores.store,
  suitcase: stores.store.suitcase,
}))(observer(LocaliseSuitcaseModalComponent));
