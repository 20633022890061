import styled from "styled-components";
import { Modal, Form } from "semantic-ui-react";
import { device, $opensans, $border, $white, $radius, $shadow } from "./_config.style";
import { COLORS } from "component/UI/common";

export const StyleModal = styled(Modal)`
  max-width: 600px !important;
  top: 60px !important;
  bottom: 60px !important;
  overflow-y: auto;
  @media ${device.tablet} {
    left: 60px !important ;
    right: 60px !important;
    width: auto !important;
  }
  @media ${device.tabletH} {
    top: 60px !important;
    bottom: 60px;
  }
`;

export const StyleHeader = styled(Modal.Header)`
  border-bottom: ${$border};
  font-family: ${$opensans} !important;
  font-weight: bold !important;
  font-size: 30px !important;
  line-height: 41px !important;
  color: ${COLORS.indigo600} !important;
  height: 80px !important;
`;

export const StyleContent = styled(Modal.Content)`
  position: absolute !important;
  overflow-y: auto !important;
  top: 80px !important;
  bottom: 80px !important;
  @media (max-height: 600px) {
    overflow-y: auto !important;
    /* height: 100% - 10px !important; */
  }
`;
export const StyleActions = styled(Modal.Actions)`
  background-color: white !important;
  height: 80px !important;
  position: absolute;
  bottom: 0 !important;
  width: 100% !important;
  padding: 20px !important;
`;

export const StyleInput = styled(Form.Input)`
  height: 40px !important;
  font-family: ${$opensans} !important;
  flex-grow: 1;
  .ui.input {
    height: 40px !important;
    font-family: ${$opensans} !important;
    font-size: 16px;
  }
`;

export const StyleCancel = styled.button`
  cursor: pointer;
  width: 100px;
  height: 40px;
  border: ${$border};
  border-radius: ${$radius};
  font-family: ${$opensans};
  font-weight: bold;
  font-size: 15px;
  background-color: ${$white};
  color: ${COLORS.red500};
  margin: 0 10px 0 0;
`;
export const StyleSave = styled.button`
  cursor: pointer;
  width: 90px;
  height: 40px;
  border-radius: ${$radius};
  font-family: ${$opensans};
  font-weight: bold;
  font-size: 15px;
  color: ${$white};
  background: ${COLORS.red500};
  box-shadow: ${$shadow};
  border: none;
`;
export const StyleChange = styled.button`
  cursor: pointer;
  width: 110px;
  height: 40px;
  border-radius: ${$radius};
  font-family: ${$opensans};
  font-weight: bold;
  font-size: 15px;
  color: ${$white};
  background: ${COLORS.red500};
  box-shadow: ${$shadow};
  border: none;
`;
