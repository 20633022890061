import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Avatar } from "component/Avatar";
import { CollaborateDropdown } from "./CollaborateDropdown";
import { CommentMentions } from "./CommentMentions";
import Store from "common/store";
import { COLORS } from "component/UI/common";
import { DashboardComment } from "common/store/dashboard";
import { getMixpanel } from "common/api";
import { formatDate } from "common/helpers/dashboard";
import { IconButton } from "./components";
import { Confirm, Popup } from "semantic-ui-react";

interface Props {
  dashboardID: number;
  comment: DashboardComment;
  showResolveIcon?: boolean;
  commentWriter: boolean;
  dashboardUsers: any[];
  store?: Store;
}

const Component = (props: Props): JSX.Element => {
  const { dashboardID, comment, showResolveIcon, commentWriter, dashboardUsers, store } = props;
  const { id: commentID, avatar, first_name, last_name, created_at, updated_at, resolved, message } = comment;
  const dashboardStore = store!.dashboard;
  const [editing, setEditing] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showActionButtons, setShowActionButtons] = React.useState(false);

  const deleteMessage = async (): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Conversations > Delete Comment", { "Dashboard ID": dashboardID, "Comment ID": commentID });
    const success = await dashboardStore.deleteDashboardComment(dashboardID, commentID);
    if (success) {
      await dashboardStore.refreshDashboardComments(dashboardID);
      setShowDeleteModal(false);
    }
    // TODO: show error messages to handle endpoint call failure
  };

  const updateComment = async (message: string): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Conversations > Update Comment", {
      "Dashboard ID": dashboardID,
      "Comment ID": commentID,
      Message: message,
    });
    const success = await dashboardStore.updateDashboardComment(dashboardID, commentID, { message });
    if (success) {
      await dashboardStore.refreshDashboardComments(dashboardID);
      setEditing(false);
    }
    // TODO: show error messages to handle endpoint call failure
  };

  const updateResolve = async (): Promise<void> => {
    getMixpanel(store!).track("Collaborate > Conversations > Resolve Comment", {
      "Dashboard ID": dashboardID,
      "Comment ID": commentID,
      Resolved: !resolved ? "Yes" : "No",
    });
    const success = await dashboardStore.updateDashboardComment(dashboardID, commentID, { resolved: !resolved });
    if (success) {
      await dashboardStore.refreshDashboardComments(dashboardID);
    }
    // TODO: show error messages to handle endpoint call failure
  };

  const messageEditorDropdownOptions = [
    { text: "Edit", icon: "edit", onClick: () => setEditing(true) },
    { text: "Delete", icon: "trash alternate", onClick: () => setShowDeleteModal(true) },
  ];

  return (
    <div className="d-flex mb-3" onMouseEnter={() => setShowActionButtons(true)} onMouseLeave={() => setShowActionButtons(false)}>
      <Confirm
        open={showDeleteModal}
        content={<h3 className="text-primary m-4">Are you sure you want to delete this comment?</h3>}
        confirmButton="Delete Comment"
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={deleteMessage}
      />
      <Avatar className="cursor-pointer mr-2" color={COLORS.indigo500} image={avatar} name={`${first_name} ${last_name}`} size={25} />
      <div className="flex-grow-1">
        <div className="d-flex align-items-center justify-content-between mb-3" style={{ height: 24 }}>
          <div>
            <span className="fs-1000 fw-700" style={{ color: COLORS.grey600 }}>
              {`${first_name} ${last_name}`}
            </span>
            <span className="fs-1000" style={{ color: COLORS.grey600 }}>
              {` | ${formatDate(created_at || updated_at, "h:mm a DD")}`}
            </span>
          </div>
          {showActionButtons && (
            <div className="d-flex align-items-center">
              {showResolveIcon && (
                <Popup
                  trigger={
                    <IconButton
                      onClick={!resolved ? updateResolve : undefined}
                      iconColor={resolved ? COLORS.aqua400 : COLORS.grey600}
                      icon="check"
                    />
                  }
                  disabled={resolved}
                  content="Mark as archived"
                  position="top center"
                  size="mini"
                  inverted
                />
              )}
              {commentWriter && <CollaborateDropdown options={messageEditorDropdownOptions} />}
            </div>
          )}
        </div>
        <CommentMentions
          comment={message}
          editing={editing}
          onUpdate={(message) => updateComment(message)}
          onCancel={() => setEditing(false)}
          mentionUsers={dashboardUsers}
        />
      </div>
    </div>
  );
};

export const Comment = inject("store")(observer(Component));
