import validator from "validator";

export const composeValidators =
  (...validators) =>
  (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined);
export const required = (value) => (value ? undefined : "*Required");
export const selectionRequired = (values) => (values?.length ? undefined : "*Required"); // Used for multiple selection dropdown
export const mustBeNumber = (value) => (isNaN(value) ? "Must be a number" : undefined);
export const notRequiredMustBeNumber = (value) => (value !== undefined && isNaN(value) ? "Must be a number" : undefined); // Not required, but if provided, it must be a number
export const minValue = (min) => (value) => (isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`);
export const mustBeValidEmail = (value) => (validator.isEmail(value) ? undefined : "Must be a valid email");
export const validDate = (value) => (new Date(value).toString() === "Invalid Date" ? "Not a valid date" : undefined);
export const requiredCustomMessage = (errorMessage: string) => (value) => (value ? undefined : errorMessage);
export const validPassword = (value) => {
  if (value.length < 8) {
    return "Password must be at least 8 characters long";
  }
  if (!/[A-Z]/.test(value)) {
    return "Password must contain at least one uppercase letter";
  }
  if (!/[a-z]/.test(value)) {
    return "Password must contain at least one lowercase letter";
  }
  if (!/[0-9]/.test(value)) {
    return "Password must contain at least one number";
  }
  if (!/([^\s\w]|[_])/.test(value)) {
    return "Password must contain at least one special character (e.g. ~!@#$%^&*)";
  }
  return undefined;
};
// Used for checking if the current field's value matches the value of passed field. Optional error message can be passed.
export const valuesMustMatch = (fieldToMatch: string, errorMessage?: string) => (value: any, allValues: Record<string, any>) => {
  return value === allValues[fieldToMatch] ? undefined : errorMessage || "Values do not match";
};
