import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Protected from "component/protected";
import { Icon, Menu, SemanticICONS } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { Link, Redirect, useParams } from "react-router-dom";
import { Account } from "pages/Settings/includes/Account";
import Organisations from "pages/Settings/includes/Organisations";
import Members from "component/Members/Members";
import { ColorPalettesList } from "component/ColorPalettesList";
import { PartnerPendingInvitations } from "../PartnerOrganisations/PartnerPendingInvitations";
import { PartnerOrganisationsList } from "../PartnerOrganisations/PartnerOrganisationsList";
import { PartnershipConfirmModal } from "../PartnerOrganisations/includes/PartnershipConfirmModal";
import { MenuLink } from "../PartnerOrganisations/includes/PartnerOrganisationsButtons";
import { ChangePassword } from "./includes/ChangePassword";

const SettingsComponent = ({ store }): JSX.Element => {
  const [showSignOutModal, setShowSignOutModal] = React.useState(false);

  const { user } = store;
  const isOwnerOrAdmin = user.group.users.find((u) => u.id === user.id).admin;

  const settingsItems = {
    account: "Account", // URL path: "/settings/account"
    org: "Details", // URL path: "/settings/org"
    members: "Members", // URL path: "/settings/org/members"
    palettes: "Colour Palettes", // URL path: "/settings/org/palettes"
    invitations: "Invitations", // URL path: /settings/org/partners/invitations
    partners: "Current Partners", // URL path: /settings/org/partners
  };
  if (user.group.enforce_mfa) {
    settingsItems["password"] = "Change Password"; // URL path: "/settings/password"
  }

  const { settingsItemID } = useParams<{ settingsItemID: string }>();
  const settingsItem = settingsItems[settingsItemID];

  const { breakpoint } = store.ui;
  const isMobileScreen = breakpoint === "mobile";

  const isItemActive = (item) => settingsItem === item;

  return (
    <>
      {/* Always redirect to "/settings/account" when the URL doesn't exist */}
      {Object.keys(settingsItems).indexOf(settingsItemID) < 0 && <Redirect to={"/settings/account"} />}
      <Helmet>
        <title>{`Settings: ${settingsItem}`}</title>
      </Helmet>
      <PartnershipConfirmModal
        isOpen={showSignOutModal}
        closeModal={() => setShowSignOutModal(false)}
        confirmModal={() => store.logout()}
        header="Sign Out"
        content="Are you sure you want to sign out?"
        confirmButtonText="Sign Out"
      />
      <div
        className="bg-white d-flex flex-column flex-md-row"
        style={{ height: `${isMobileScreen ? "auto" : "calc(100vh - 56px)"}`, marginTop: -34, marginBottom: -20 }}
      >
        <div
          className={`pt-5 pb-3 px-4 overflow-auto ${isMobileScreen || "border-right"}`}
          style={{ width: `${isMobileScreen ? "100%" : "260px"}`, minWidth: 260 }}
        >
          <div className="position-relative h-100" style={{ minHeight: 480 }}>
            <h3 className="ff-primary text-secondary mt-0">Settings</h3>
            <Menu vertical secondary fluid className="ff-primary fs-1000 ml-0">
              <Link to="/settings/account">
                <MenuLink active={isItemActive("Account")} label="Account" icon="user" />
              </Link>
              {user.group.enforce_mfa && (
                <Link to="/settings/password">
                  <MenuLink active={isItemActive("Change Password")} label="Change Password" icon="lock" />
                </Link>
              )}
              <p className="text-muted fw-700" style={{ marginLeft: 15, marginTop: 15 }}>
                Your Organisation
              </p>
              <Link to="/settings/org">
                <MenuLink id="userflow-element-settings-details" active={isItemActive("Details")} label="Details" icon="edit" secondary />
              </Link>
              <Link to="/settings/org/members">
                <MenuLink id="userflow-element-settings-members" active={isItemActive("Members")} label="Members" icon="group" secondary />
              </Link>
              <Link to="/settings/org/palettes">
                <MenuLink
                  id="userflow-element-settings-palettes"
                  active={isItemActive("Colour Palettes")}
                  label="Colour Palettes"
                  icon={"palette" as SemanticICONS}
                  secondary
                />
              </Link>
              <p className="text-muted fw-700" style={{ marginLeft: 15, marginTop: 15 }}>
                Partner Organisations
              </p>
              {isOwnerOrAdmin && (
                <Link to="/settings/org/partners/invitations">
                  <MenuLink
                    id="userflow-element-settings-invitations"
                    active={isItemActive("Invitations")}
                    label="Invitations"
                    icon="cog"
                    secondary
                  />
                </Link>
              )}
              <Link to="/settings/org/partners">
                <MenuLink
                  id="userflow-element-settings-partners"
                  active={isItemActive("Current Partners")}
                  label="Current Partners"
                  icon="handshake outline"
                  secondary
                />
              </Link>
            </Menu>
            <Menu vertical fluid className="ff-primary fs-1000 position-absolute shadow-none" style={{ bottom: 20 }}>
              <Menu.Item onClick={() => setShowSignOutModal(true)} className="fw-700">
                <Icon name="sign out alternate" />
                Sign Out
              </Menu.Item>
            </Menu>
          </div>
        </div>
        <div className="py-5 px-4 flex-sm-grow-1 overflow-auto">
          <div className="position-relative" style={{ maxWidth: 900 }}>
            <h4 className="text-secondary mt-2">{settingsItem}</h4>
            {isItemActive("Account") && <Account />}
            {isItemActive("Change Password") && <ChangePassword />}
            {isItemActive("Details") && <Organisations store={store} />}
            {isItemActive("Members") && <Members store={store} />}
            {isItemActive("Colour Palettes") && <ColorPalettesList store={store} />}
            {isItemActive("Invitations") && <PartnerPendingInvitations />}
            {isItemActive("Current Partners") && <PartnerOrganisationsList />}
          </div>
        </div>
      </div>
    </>
  );
};

export const Settings = Protected(
  inject((stores: any) => ({
    store: stores.store,
  }))(observer(SettingsComponent)),
);
