import * as React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { Button, Checkbox, Divider, Icon, Label, Menu, Modal } from "semantic-ui-react";
import Store from "common/store";
import { getMixpanel } from "common/api";
import { PublicLink } from "./includes/PublicLink";
import { ManageAccessList } from "./includes/ManageAccessList";
import { AddAccessWidget } from "./includes/AddAccessWidget";
import { StyleTab } from "component/Suitcases/SuitcasesContent.style";

export type IType = "organisation" | "member";

interface IManageAccess {
  usedBy: "dashboards" | "suitcases";
  isOpen: boolean;
  closeModalHandler: () => void;
  publicLinkItem: any;
  publicLinkChangeHandler: (value) => void;
  accessOrganisations: any[];
  accessUsers: any[];
  accessChangeHandler: (type, id, accessLevel) => void;
  accessAddHandler: (type, ids, accessLevel) => Promise<boolean>;
  canReadOnlyDuplicate: boolean;
  readOnlyDuplicateChangeHandler: (value) => Promise<void>;
  store?: Store;
  dashboardStore?: any;
}

const MANAGE_ACCESS_TAB: IType[] = ["organisation", "member"];

const organisationHeaderItems = ["", "Name", "Access"];
const userHeaderItems = ["", "Name", "Organisation", "Access"];

const ManageAccessModalComponent = ({
  usedBy,
  isOpen,
  closeModalHandler,
  publicLinkItem,
  publicLinkChangeHandler,
  accessOrganisations,
  accessUsers,
  accessChangeHandler,
  accessAddHandler,
  canReadOnlyDuplicate,
  readOnlyDuplicateChangeHandler,
  store,
}: IManageAccess): JSX.Element => {
  const [activeTab, setActiveTab] = React.useState<IType>("organisation");
  const [showAddAccessWidget, setShowAddAccessWidget] = React.useState(false);

  const isDashboardOwner = accessUsers?.find((user) => user.access_level === "owner")?.id === store!.user!.id;

  const options =
    activeTab === "organisation"
      ? store!.group.allOrganisations.map((org) => ({
          id: org.id,
          label: org.name,
          value: org.name,
          isDisabled: !!accessOrganisations?.find((group) => group.id === org.id), // Disable the group option if it's already exists in "accessOrganisations"
        }))
      : store!.group.allOrganisations.map((org) => ({
          label: org.name,
          options: org.users.map((user) => ({
            id: user.id,
            label: `${user.first_name} ${user.last_name}`,
            value: user.id,
            isDisabled: !!accessUsers.find((u) => u.id === user.id), // Disable the user option if it's already exists in "accessUsers",
          })),
        }));

  const tabChangeHandler = (activeTabIndex) => {
    getMixpanel(store!).track("Change Manage Access Tab", { Page: "Dashboards", Type: MANAGE_ACCESS_TAB[activeTabIndex].toUpperCase() });
    setActiveTab(MANAGE_ACCESS_TAB[activeTabIndex]);
  };

  const accessAddConfirmHandler = async (ids, accessLevel) => {
    const success = await accessAddHandler(activeTab === "organisation" ? "groups" : "users", ids, accessLevel);
    if (success) {
      setShowAddAccessWidget(false);
    }
  };

  React.useEffect(() => {
    setShowAddAccessWidget(false);
    setActiveTab("organisation");
  }, [isOpen]);

  const tabItems = [
    {
      menuItem: (
        <Menu.Item key="Organisations">
          Organisations
          <Label circular className="bg-grey">
            {accessOrganisations?.length}
          </Label>
        </Menu.Item>
      ),
      render: () => (
        <ManageAccessList
          headerItems={organisationHeaderItems}
          listItems={accessOrganisations}
          dropdownOnChange={accessChangeHandler}
          isEditable={isDashboardOwner}
          userOrgID={store!.user!.group.id}
        />
      ),
    },
    {
      menuItem: (
        <Menu.Item key="Members">
          Members
          <Label circular className="bg-grey">
            {accessUsers?.filter((user) => user.access_level !== "owner").length}
          </Label>
        </Menu.Item>
      ),
      render: () => (
        <ManageAccessList
          headerItems={userHeaderItems}
          listItems={accessUsers}
          dropdownOnChange={accessChangeHandler}
          isEditable={isDashboardOwner}
        />
      ),
    },
  ];

  return (
    <Modal closeIcon size="small" open={isOpen} onClose={closeModalHandler}>
      <Modal.Header className="fs-2500 fw-700 text-secondary">Manage Access</Modal.Header>
      <Modal.Content scrolling style={{ minHeight: 550 }}>
        {showAddAccessWidget ? (
          <AddAccessWidget
            type={activeTab}
            onClose={() => setShowAddAccessWidget(false)}
            options={options}
            onConfirm={(ids, accessLevel) => accessAddConfirmHandler(ids, accessLevel)}
          />
        ) : (
          <>
            <PublicLink type={usedBy} item={publicLinkItem} onChange={publicLinkChangeHandler} />
            <Divider className="mt-5" />
            <div className="position-relative">
              <StyleTab
                menu={{ secondary: true, pointing: true }}
                panes={tabItems}
                activeIndex={MANAGE_ACCESS_TAB.indexOf(activeTab)}
                onTabChange={(_e, { activeIndex }) => tabChangeHandler(activeIndex)}
                marginbottom={10}
              />
              {isDashboardOwner && (
                <Button color="purple" onClick={() => setShowAddAccessWidget(true)} style={{ position: "absolute", right: 0, top: 10 }}>
                  {`Add ${activeTab}s`} <Icon name="plus" className="ml-1 mr-0" />
                </Button>
              )}
            </div>
            <Divider />
            <Checkbox
              toggle
              checked={canReadOnlyDuplicate}
              size="small"
              onChange={(_, e) => readOnlyDuplicateChangeHandler(e.checked)}
              label={<label className="fs-1000 fw-600 text-dark">Allow Read-Only users to duplicate this Dashboard</label>}
            />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModalHandler}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export const ManageAccessModal = inject((stores: any) => ({
  store: stores.store,
  dashboardStore: stores.store.dashboard,
}))(observer(ManageAccessModalComponent));
