import * as React from "react";
import { useState } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { getMixpanel } from "common/api";
import { Modal, ModalHeader, ModalActions, ModalContent, Button, Input } from "semantic-ui-react";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { useHistory } from "react-router";

const CreateSuitcaseModalComponent = (props: any) => {
  const history = useHistory();
  const { isOpen, closeModal, store, parentId, openFrom } = props;
  const [suitcaseName, setSuitcaseName] = useState("");

  const createSuitcase = async () => {
    const id = await store.suitcase.createSuitcase(suitcaseName, "", [], parentId);
    getMixpanel(store).track("Suitcase > Create Sub-Suitcase", { "Create From": openFrom });
    closeModal();
    history.push(`/suitcases/${id}`);
  };

  const onChange = (_, data) => {
    setSuitcaseName(data.value);
  };

  return (
    <>
      {store.suitcase.loading && <ShowLoaderGlobal />}
      <Modal size="tiny" open={isOpen} onClose={closeModal}>
        <ModalHeader className="text-secondary">Create Suitcase</ModalHeader>
        <ModalContent className="seer-default-styles">
          <Input label="Suitcase Name" size="small" onChange={onChange} />
        </ModalContent>
        <ModalActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button color="purple" onClick={createSuitcase}>
            Create
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export const CreateSuitcaseModal = inject("store")(observer(CreateSuitcaseModalComponent));
