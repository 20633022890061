import * as React from "react";
import { Button, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import { useDrag, useDrop, XYCoord } from "react-dnd";

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface SortableItemProps {
  index: number;
  id: number;
  label: string;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}

interface InsightsOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (insightsIds: number[]) => void;
  insights: { id: number; name: string }[];
}

const SortableItem = (props: SortableItemProps) => {
  const { index, id, label, moveItem } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const itemType = "insight";
  const [{ handlerId }, drop] = useDrop({
    accept: itemType,
    collect: (monitor) => ({ handlerId: monitor.getHandlerId }),
    hover: (item: DragItem, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: itemType, index, id },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });
  drag(drop(ref));
  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={`p-3 rounded-2 border border-medium cursor-pointer mb-2 ${isDragging ? "bg-grey" : "bg-light"}`}
    >
      <p>{label}</p>
    </div>
  );
};

export const InsightsOrderModal = (props: InsightsOrderModalProps) => {
  const { isOpen, onClose, onSave, insights } = props;
  const [items, setItems] = React.useState(insights);
  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const newInsights = [...items];
    const [movedInsight] = newInsights.splice(dragIndex, 1);
    newInsights.splice(hoverIndex, 0, movedInsight);
    setItems(newInsights);
  };
  React.useEffect(() => {
    setItems(insights);
  }, [isOpen]);
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalHeader className="text-secondary">
        Manage Insight order
        <p className="fs-1250 text-dark">Drag and drop to re-order and hit save to confirm your updates.</p>
      </ModalHeader>
      <ModalContent scrolling>
        {items.map((insight, idx) => {
          const { id, name } = insight;
          return <SortableItem key={id} index={idx} id={id} label={name} moveItem={moveItem} />;
        })}
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="purple" onClick={() => onSave(items.map((insight) => insight.id))}>
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
};
