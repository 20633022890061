import { includes } from "lodash";
import { database } from "common/api";
import Store from "common/store";
import { ls } from "./storage";
import { ObjectAny } from "common/helpers/types";
import { environment } from "common/constants";

export interface CheckUserEmailResponse {
  userExists: boolean;
  mfaEnforced?: boolean;
}

export const checkUserEmail = async (email: string): Promise<CheckUserEmailResponse> => {
  const res: any = await database.post("users/check", { email });
  const checkResult: CheckUserEmailResponse = { userExists: res.statusCode === 200 };
  if (res.statusCode === 200) {
    checkResult.mfaEnforced = res.body.data?.user?.enforce_mfa;
  }
  return checkResult;
};

const tempUnlayerUsers = [774, 277, 650, 824, 820]; // @TODO - remove user restriction after go live (currently Seer Team - Ricki, Cat, Lavinia, Ryan, Nik)
export const getFeaturePermissions = (store: Store): ObjectAny => {
  const unlayerEditor =
    includes(["Plus", "Unlimited"], store.userPlan) && (environment !== "production" || includes(tempUnlayerUsers, store.user?.id));
  return {
    unlayerEditor,
    // @TODO - add more feature checks..
  };
};

export const getExplorePath = () => (ls.getItem("isAdvancedExploreUser") ? "/builder/new" : "/explore");
