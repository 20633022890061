import { observable, action, computed } from "mobx";
import { io, Socket } from "socket.io-client";
import { $warning } from "./styledComponents/_config.style";
import Suitcase from "./store/suitcase";
import Insight from "./store/insight";
import InsightBuilder from "./store/insightbuilder";
import Dataset from "./store/dataset";
import Dashboard from "./store/dashboard";
import Favourite from "./store/favourite";
import { database, getMixpanel } from "./api";
import Group from "./store/group";
import NotificationsStore from "./store/notifications";
import ColorPalette from "./store/colorPalette";
import Alert from "./store/alert";
import { Builder } from "./store/builder";
import { backendUrl, environment, mixpanelProxyUrl, hubspotScriptSrc, userflowToken } from "./constants";
import { UI } from "./store/ui";
import { getLastTouchUTMParams } from "./helpers/mixpanel";
import { ls, cookies } from "common/helpers/storage";
import userflow from "userflow.js";
import CompletenessMeasure from "./store/completenessMeasure";
import { COLORS } from "component/UI/common";
import { ObjectAny } from "./helpers/types";
import { DateTime } from "luxon";
import AdminActions from "./store/adminAction";

// typescript interfaces
export interface IUser {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  visible: boolean;
  bio: string;
  avatar: string;
  banner: string;
  location: string;
  organisation: string;
  category: string;
  website: string;
  links: string[];
  phone: string;
  group: any;
  alerts: any;
  show_homepage_help: boolean;
  onboarding: null | ObjectAny;
  form_terms_accepted?: boolean;
  created_at: string;
}

interface MagicLinkProps {
  user_id: string;
  token: string;
}

interface ReAuthenticatingProps {
  token: string;
}

interface StandardLoginProps {
  email: string;
  verification_code: string;
  password?: string;
}

export type SignInDataProps = StandardLoginProps | MagicLinkProps | ReAuthenticatingProps;

export default class Store {
  @observable group_types: any = [];
  @observable loading = false; // @TODO - unsure if even needed but is used on insight page, embedinsight page and insight store, remove if possible
  @observable user?: IUser;
  @observable token: string | null = null;
  @observable contributor_token: string | null = null;
  @observable ready = false;
  @observable notification = { show: false, message: "" };
  @observable notifications: any = [];
  @observable userPlan: "Standard" | "Plus" | "Unlimited" = "Standard";
  @observable groupAddOns: any[] = [];
  @observable upgradeShow = false; // Upgrade Modal
  // Alert Bar
  @observable alertColors: any = {
    error: COLORS.red500,
    warning: $warning,
  };

  socket: Socket;
  suitcase = new Suitcase(this);
  insight = new Insight(this);
  insightbuilder = new InsightBuilder(this);
  dataset = new Dataset(this);
  dashboard = new Dashboard(this);
  favourite = new Favourite(this);
  group = new Group(this);
  notificationsProvider = new NotificationsStore(this);
  colorPalette = new ColorPalette(this);
  builder = new Builder(this);
  alert = new Alert(this);
  ui = new UI(this);
  // TODO: Move this into the "dataset" store as a child store after "URL datasets" work is merged
  completenessMeasure = new CompletenessMeasure(this);
  adminActions = new AdminActions(this);
  mixpanelConfig: any = { ignore_dnt: true, api_host: mixpanelProxyUrl };
  mixpanelConfigUpdate = false; // flag for whether to update config

  constructor() {
    this.socket = io(backendUrl, { withCredentials: true });
    // token is stored in LS, cookie token is useful where using external services where auth needs to be set prior to page load
    this.token = ls.getItem("token") || cookies.get("token");
    this.contributor_token = ls.getItem("contributor_token");
    // @TODO - subscribe to socket events in the components that need to respond to them, not in the global store
    this.socket.on("updateSuitcases", () => {
      this.suitcase.getSuitcases(this.suitcase.active?.id);
    });
    this.socket.on("insightComment", () => {
      this.insight.result && this.insight.comments.getComments(this.insight.result.id);
    });
    this.socket.on("notifications", () => {
      this.notificationsProvider.getNotifications();
    });
    this.mixpanelCheck();

    // Captures UTM parameters and registers them as super super properties to be tracked with all future events
    // Track Last Touch UTM: https://help.mixpanel.com/hc/en-us/articles/360001337103-Last-Touch-UTM-Tags
    // URL example: http://example.com/landingpage?utm_medium=ppc&utm_source=adwords&utm_campaign=snow%20boots&utm_content=durable%20%snow%boots
    getMixpanel(this)?.register(getLastTouchUTMParams(window.location.href), 1);
  }

  @computed get authenticated() {
    return this.user !== undefined;
  }

  @action handleUpgradeShow(vis: boolean) {
    this.upgradeShow = vis;
  }

  @action setUserPlan(groupTypeID: number): void {
    // Group ID in production - 1: Standard; 2: Plus; 3: Unlimited
    if (groupTypeID === 2) {
      this.userPlan = "Plus";
    } else if (groupTypeID === 3) {
      this.userPlan = "Unlimited";
    }
  }

  @action async boot() {
    this.token && !this.user && (await this.reloginUserFromAuthToken());
    this.ready = true;
  }

  @action async getGroupTypes() {
    const g_res: any = await database.get("group-types");
    if (g_res.statusCode === 200) {
      this.group_types = g_res.body.data.group_types;
    }
  }

  @action logout() {
    Object.assign(this, {
      user: undefined,
      token: null,
    });

    this.suitcase = new Suitcase(this);
    this.insight = new Insight(this);
    this.dataset = new Dataset(this);
    this.group = new Group(this);
    ls.clear();
    cookies.remove("token", { path: "/", sameSite: "none", secure: true });
  }

  /** Logging in via JWTTOKEN */
  @action async reloginUserFromAuthToken() {
    if (window.location.pathname === "/invite" || window.location.pathname === "/resetpassword") {
      return this.logout();
    }
    const token = this.token;
    const res: any = await database.post("users/auth", { token });
    if (res.body.data) {
      const { user, JWTTOKEN } = res.body.data;
      ls.setItem("token", JWTTOKEN);
      this.token = JWTTOKEN;
      this.loggedIn(user);
    }
  }

  // Logging in the user with token & email login
  @action async logInUser(signInData: SignInDataProps) {
    const res: any = await database.post("users/auth", signInData);
    if (res.body.data) {
      const { user, JWTTOKEN } = res.body.data;
      ls.setItem("token", JWTTOKEN);
      this.token = JWTTOKEN;
      this.loggedIn(user);
      return true;
    } else {
      return false;
    }
  }

  @action logInContributor(token: string): void {
    ls.setItem("contributor_token", token);
    this.contributor_token = token;
  }

  @action logOutContributor(): void {
    ls.removeItem("contributor_token");
    this.contributor_token = null;
  }

  // Once user is logged in data is gathered
  @action async loggedIn(user) {
    user.visible = user.public;
    this.user = user;
    // Store custom color palettes to customColorPalettes
    this.colorPalette.customColorPalettes = [...user.group.color_palettes];
    // Combine custom color palettes and default color palettes for ColorPalettesList.tsx
    this.colorPalette.allColorPalettes = [...this.colorPalette.customColorPalettes, ...this.colorPalette.defaultColorPalettes];

    this.setUserPlan(user.group.group_type_id);
    this.groupAddOns = user.group.add_ons;

    this.socket.emit("join", { id: this.user!.id });
    this.notificationsProvider.getNotifications();

    if (user.group.id === 102 || user.group.id === 558 || environment !== "production") {
      document.body.className += " is-admin"; // is-admin to show css hidden features, 102 === seer team group id, 558 === seer tutorial group id
    }

    const token = ls.getItem("token");
    if (token) {
      cookies.set("token", token, { path: "/", sameSite: "none", secure: true });
    }

    // Init `userflow`
    userflow.init(userflowToken);
    let trial_remaining_days: number | undefined;
    if (user.group.trial_end_date) {
      const trialEndDate = DateTime.fromISO(user.group.trial_end_date.slice(0, 10));
      const today = DateTime.fromISO(new Date().toISOString().slice(0, 10));
      trial_remaining_days = trialEndDate.diff(today, "days").toObject().days;
    }
    userflow.identify(user.id, {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      group_id: user.group.id,
      group_name: user.group.name,
      group_plan: user.group.group_type_id === 1 ? "Standard" : user.group.group_type_id === 2 ? "Plus" : "Unlimited",
      signed_up_at: user.created_at,
      trial_remaining_days,
    });
  }

  @action async removeInvite(invitation_id: number) {
    const res: any = await database.delete(`invitations/${invitation_id}`, null, this.token!);
    if (res.statusCode === 200) {
      this.user!.group.invitations = this.user!.group.invitations.filter((invite) => invite.id !== invitation_id);
      if (this.suitcase.active) {
        // Remove the invitation from the current Suitcase as well if any
        this.suitcase.active.invitations = this.suitcase.active.invitations.filter((invite) => invite.id !== invitation_id);
      }
    } else {
      // @TODO - error handling?
    }
  }

  mixpanelCheck = async (): Promise<void> => {
    // check if proxy is available, if not revert to direct mixpanel api
    const res: any = await fetch(`${mixpanelProxyUrl}/health-check`, { cache: "no-store", mode: "no-cors" }).catch(() => false);
    if (!res?.ok) {
      this.mixpanelConfig = { ...this.mixpanelConfig, api_host: "https://api-js.mixpanel.com" }; // revert to default api_host
      this.mixpanelConfigUpdate = true;
    }
    // check for dnt / ad blocker and update mixpanel profile information with associated data
    const dntEnabled = !!navigator?.doNotTrack;
    // note: this script won't load with ad blocker enabled so is our best test option currently (no npm packages that do good detection)
    const canLoadHubspotTrackingScript = await fetch(hubspotScriptSrc)
      .then((res) => !!res.ok)
      .catch(() => false);
    getMixpanel(this)?.people.set({
      "Do Not Track Enabled": dntEnabled,
      "Adblocker Enabled": !canLoadHubspotTrackingScript,
    });
  };

  hasGroupAddOn = (addOn: string): boolean => this.groupAddOns.findIndex((a) => a.name === addOn) >= 0;
}
