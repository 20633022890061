import * as React from "react";
import { observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import SuitcaseStore from "../common/store/suitcase";
import { database, getMixpanel } from "../common/api";
import { Button, Icon, Loader } from "semantic-ui-react";
import CardListView from "./CardListView";
import TableListView from "./TableListView";
import { Hr } from "../pages/Suitcase/suitcaseOverviewPage.styles";
import { COLORS } from "./UI/common";

const Heading = styled.h2`
  font-size: 30px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  line-height: 41px;
  color: ${COLORS.indigo600};
`;

const Upload = styled.div`
  font-size: 18px;
  color: #6c3563;
  // float: right;
`;

type PropsType = RouteComponentProps<any> & {
  store?: SuitcaseStore;
  readonly?: boolean;
  publicKey?: string;
};

class Attachments extends React.Component<PropsType> {
  store = this.props.store!;
  state = { layout: "block" };
  input: HTMLInputElement | null | void = name;
  project_ids = this.store.parent.suitcase.projects.map((project) => project.id);
  prepacked_suitcase_user_ids = this.store.parent.suitcase.prepackedprojects.map((user) => user.id);

  types = {
    ppt: "powerpoint",
    pptx: "powerpoint",
    doc: "word",
    docx: "word",
    xlsx: "excel",
    xls: "excel",
    png: "image",
    jpg: "image",
    jpeg: "image",
    gif: "image",
    mp4: "video",
    avi: "video",
    mov: "video",
    mkv: "video",
    pdf: "pdf",
  };

  async uploadFiles() {
    const keys = Object.keys(this.input!.files!);
    for (let i = 0; i < keys.length; i++) {
      const file = this.input!.files![keys[i]];
      const name = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        await database.post(`suitcases/${this.store.active!.id}/files`, { name, file: reader.result }, this.store.parent.token!);
        if (i === keys.length - 1) {
          this.store.getSuitcases(this.store.active!.id);
          this.store.files = false;
        }
      };
      getMixpanel(this.store.parent).track("Add attachment");
    }
  }

  changeLayout(selectedLayout: string) {
    this.setState({ layout: selectedLayout });
    console.log(this.state.layout);
  }

  render() {
    const { publicKey, readonly } = this.props;
    const deleteFile = (id: number) => {
      if (window.confirm("Are you sure you want to delete this file?")) {
        console.log("Deleting file", id);
        this.store.deleteFile(id);
        getMixpanel(this.store.parent).track("Delete attachment");
      }
    };
    const viewOptions = [
      {
        value: "block",
        icon: "block layout",
      },
      {
        value: "grid",
        icon: "grid layout",
      },
      {
        value: "list",
        icon: "list layout",
      },
    ];

    return (
      <div>
        <div style={{ display: "inline-block", width: "100%", marginTop: "100px" }}>
          <Heading style={{ float: "left" }}>Attachments</Heading>

          <div className="text-right pdf-hide">
            {publicKey ? null : (
              <>
                {this.store.files && (
                  <Upload>
                    Uploading
                    <Loader active inline />{" "}
                  </Upload>
                )}
                {!this.store.files && !readonly && (
                  <Button
                    style={this.store.parent.user!.group.expired ? { cursor: "not-allowed" } : {}}
                    onClick={() => (this.store.parent.user!.group.expired ? null : document.getElementById("fileUpload")!.click())}
                  >
                    Attach files
                    <Icon name="paperclip" className="ml-2" />
                  </Button>
                )}
                <input
                  style={{ display: "none" }}
                  id="fileUpload"
                  type="file"
                  onChange={() => {
                    this.store.files = true;
                    this.uploadFiles();
                  }}
                  ref={(ref) => (this.input = ref)}
                  multiple
                />
              </>
            )}
            <Button.Group
              style={{ marginLeft: "30px" }}
              role="radiogroup"
              aria-label="Attachments Layout"
              className="d-none d-lg-inline-flex"
            >
              {viewOptions.map((viewOption, idx) => (
                <Button
                  role="radio"
                  aria-checked={this.state.layout === viewOption.value}
                  aria-label={`${viewOption.value} view`}
                  key={idx}
                  icon={viewOption.icon}
                  style={this.state.layout === viewOption.value ? { color: "darkslategrey" } : { color: "lightslategrey" }}
                  active={this.state.layout === viewOption.value}
                  onClick={() => this.changeLayout(viewOption.value)}
                />
              ))}
            </Button.Group>
          </div>
        </div>

        <Hr />

        {this.state.layout === "list" && <TableListView store={this.store} isPublicURL={!!publicKey} />}

        {(this.state.layout === "grid" || this.state.layout === "block") && (
          <div>
            <CardListView
              layout={this.state.layout}
              type="attachment"
              publicKey={publicKey}
              list={this.store.active!.files.map((file) => ({
                id: file.id,
                name: file.name,
                itemUrl: file.file,
                imageUrl: file.banner,
                type: this.types[file.name.split(".")[file.name.split(".").length - 1]],
                created_at: file.created_at,
              }))}
              cardActions={
                !publicKey && !readonly
                  ? [
                      {
                        name: "Delete",
                        callback: deleteFile,
                      },
                    ]
                  : []
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(observer(Attachments));
