import React = require("react");
import { inject, observer } from "mobx-react";
import { DateTime } from "luxon";
import { withRouter, RouteComponentProps } from "react-router";
import { Container, CommentDetailContainer, AvatarContainer, CommentDetails, Hr, MainContainer, ReplyComment } from "./comment.style";

import Response from "./comment";

import Insight from "../../common/store/insight";
import Store from "../../common/store";
import { RichMentionEditor } from "../richMentionEditor";
import { Avatar } from "../Avatar";
import { COLORS } from "component/UI/common";
import { Button, Icon } from "semantic-ui-react";

type PropsType = RouteComponentProps<any> & {
  insight: Insight;
  comment: any;
  owner: boolean;
  edit: boolean;
  reply: boolean;
  depth: number;
  store: Store;
  readonly?: boolean;
};

export default withRouter(
  inject("store")(
    observer(
      class Comment extends React.Component<PropsType> {
        store = this.props.store;

        deleteComment(id: number) {
          if (window.confirm("Are you sure you want to delete this comment?")) {
            this.props.insight.comments.deleteComment(id);
          }
        }

        render() {
          const { insight, comment, owner, edit, reply, depth, readonly } = this.props;
          const { comments } = insight;
          return (
            <Container id={`comment${comment.id}`}>
              <AvatarContainer
                owner={owner}

                // TODO: Look into user profiles that used to exist
                // onClick={() =>
                //   this.props.history.push(`/user/${comment.user_id}`)
                // }
              >
                <Avatar name={`${comment.first_name} ${comment.last_name}`} image={comment.avatar} />
              </AvatarContainer>
              <MainContainer owner={owner}>
                <CommentDetails owner={owner}>
                  <b
                    style={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "18px",
                      lineHeight: "21px",
                      color: COLORS.indigo600,
                    }}
                  >
                    {comment.first_name} {comment.last_name}
                  </b>{" "}
                  <CommentDetailContainer>
                    {DateTime.fromISO(comment.created_at).setZone("UTC+11").toFormat("d LLLL yyyy")}{" "}
                    {comment.edited && <> - Edited {DateTime.fromISO(comment.edited_at).setZone("UTC+11").toFormat("d LLLL yyyy")}</>}
                  </CommentDetailContainer>
                </CommentDetails>

                <RichMentionEditor
                  editable={edit}
                  save={(commentText) => {
                    comments.editComment(commentText, comment.id);
                    comments.updateComment(comment);
                  }}
                  saveButtonText="Update"
                  defaultValue={comment.message}
                  cancel={() => (comments.ids.edit = -1)}
                  mentionUsers={this.store.suitcase.active!.users.filter((user) => user.id !== this.store.suitcase.active!.user_id)}
                  mentionOrgs={
                    readonly
                      ? []
                      : this.store.group.partnerOrganisations.filter((org) => {
                          const userIdsWithSuitcaseAccess = this.store.suitcase.active!.users.map((user) => user.id);
                          return org.users.some((orgUser) => userIdsWithSuitcaseAccess.includes(orgUser.id));
                        })
                  }
                />

                {!this.props.readonly && !reply && !edit && (
                  <Button onClick={() => comments.openReply(comment.id)}>
                    Reply <Icon name="reply" className="ml-2" />
                  </Button>
                )}
                {owner && !reply && !edit && (
                  <>
                    <Button onClick={() => comments.editComment(comment.message, comment.id)}>
                      Edit <Icon name="edit" className="ml-2" />
                    </Button>
                    <Button onClick={() => comments.deleteComment(comment.id)} style={{ display: owner ? "inline-block" : "none" }}>
                      Delete
                      <Icon name="trash alternate outline" className="ml-2" />
                    </Button>
                  </>
                )}
                {reply && (
                  <>
                    <div style={{ margin: "2px 0 5px 0" }}>
                      <ReplyComment id={`reply${comment.id}`} onChange={(e: any) => (comments.replyMessage = e.currentTarget.value)} />
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <Button className="mr-3" onClick={() => (comments.ids.reply = -1)}>
                        Cancel <Icon name="times" className="ml-2 mr-0" />
                      </Button>
                      <Button color="purple" className="mr-0" id={`reply${comment.id}p`} onClick={() => comments.postReply(comment.id)}>
                        Reply <Icon name="reply" className="ml-2 mr-0" />
                      </Button>
                    </div>
                  </>
                )}
                {depth < comments.maxDepth && (
                  <>
                    <Hr style={{ margin: `${edit ? 63 : reply ? 75 : 20}px 0 30px` }} />
                    {comments.getReplies(comment.id).map((response, i) => {
                      const owner = response.user_id === insight.parent.user!.id;
                      const edit = response.id === comments.ids.edit;
                      const reply = response.id === comments.ids.reply;
                      return (
                        <span key={i}>
                          <Response
                            reply={reply}
                            edit={edit}
                            owner={owner}
                            insight={insight}
                            comment={response}
                            depth={depth + 1}
                            store={this.store}
                          />
                        </span>
                      );
                    })}
                  </>
                )}
              </MainContainer>
              {depth >= comments.maxDepth && (
                <>
                  <Hr style={{ margin: `${edit ? 63 : reply ? 75 : 20}px 0 30px` }} />
                  {comments.getReplies(comment.id).map((response, i) => {
                    const owner = response.user_id === insight.parent.user!.id;
                    const edit = response.id === comments.ids.edit;
                    const reply = response.id === comments.ids.reply;
                    return (
                      <span key={i}>
                        <Response
                          reply={reply}
                          edit={edit}
                          owner={owner}
                          insight={insight}
                          comment={response}
                          depth={depth + 1}
                          store={this.store}
                        />
                      </span>
                    );
                  })}
                </>
              )}
            </Container>
          );
        }
      },
    ),
  ),
);
