import styled from "styled-components";
import TextareaAutosize from "react-autosize-textarea";

export const Container = styled.div``;

export const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c3c3c3;
`;

export const AvatarContainer = styled.div<{ owner: boolean }>`
  cursor: pointer;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50px;
  float: ${(props) => (props.owner ? "none" : "none")};
  vertical-align: top;
`;

export const CommentDetails = styled.div<{ owner: boolean }>`
  margin: 0;
  text-align: ${(props) => (props.owner ? "left" : "left")};
  margin-top: 9px;
`;
export const CommentDetailContainer = styled.p`
  font-family: Open Sans;
  font-size: 15px;
  line-height: 21px;
  color: #2d2d2d;
  float: right;
`;

export const MainContainer = styled.div<{ owner: boolean }>`
  display: inline-block;
  width: calc(100% - 60px);
  float: ${(props) => (props.owner ? "none" : "none")};
  margin-bottom: 5px;
  margin-left: 10px;
`;

// @TODO - fix me with correct typing for the react-autosize-textarea module
export const ReplyComment = styled<any>(TextareaAutosize)`
  outline: none;
  resize: none;
  background-color: #fcfcfc;
  border: 1px solid #bcbcbc;
  font-size: 15px;
  padding: 10px;
  display: block;
  min-height: 80px;
  position: relative;
  border-radius: 4px;
  width: 100%;
`;
