import * as React from "react";
import { useHistory } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import Store from "common/store";
import { getMixpanel } from "common/api";
import { SuitcasesGrid } from "./includes/SuitcasesGrid";
import { SuitcasesList } from "./includes/SuitcasesList";
import { ShowLoaderGlobal } from "component/LoaderGlobal";
import { Button, Icon, Popup, Input, Dropdown, SemanticICONS } from "semantic-ui-react";
import { StyleHeading, StyleTab, StyledControlsFilters, StyledMenuItem, StyledContainer } from "./SuitcasesContent.style";
import { applySearchFilter } from "common/helpers/suitcases";
import { MoveDuplicateModal } from "component/MoveDuplicateModal/MoveDuplicateModal";
import { EmptyStateSecondary, ListPageEmptyStateDescription } from "component/EmptyState/EmptyState";

interface ISuitcasesContent {
  store?: Store;
}

const layoutOptions = [
  { value: "list", icon: "list layout" },
  { value: "grid", icon: "grid layout" },
];

const SuitcasesContentComponent = ({ store }: ISuitcasesContent): JSX.Element => {
  const history = useHistory();
  const [layout, setLayout] = React.useState("list");
  const [searchInput, setSearchInput] = React.useState("");
  const [showCheckboxes, setShowCheckboxes] = React.useState(false);
  const [selectedSuitcases, setSelectedSuitcases] = React.useState(new Array<number>());
  const [showMoveSuitcaseModal, setShowMoveSuitcaseModal] = React.useState(false);
  const { suitcase, userPlan } = store!;

  const createSuitcase = async () => {
    const id = await suitcase.createSuitcase("Untitled suitcase", "", []);
    getMixpanel(store!).track("Create New Suitcase", { "Suitcase Id": id });
    history.push(`/suitcases/${id}`);
  };

  const bulkMoveSuitcasesAction = (destination) => {
    suitcase.bulkMoveSuitcases(selectedSuitcases, destination);
    getMixpanel(store!).track("Bulk Move Suitcases", {
      "Moved Suitcases Ids": selectedSuitcases,
      "Destination Suitcase Id": destination,
    });
    setShowMoveSuitcaseModal(false);
    setSelectedSuitcases([]);
  };

  const changeLayout = (selectedLayout: string) => {
    setLayout(selectedLayout);
    getMixpanel(store!).track("Suitcases > Change layout", { Layout: selectedLayout });
  };

  const handleTabChange = (_e: React.MouseEvent<HTMLDivElement>, { activeIndex }: { activeIndex?: any }) => {
    suitcase.activeSuitcasesTab = activeIndex;
    setShowCheckboxes(false);
    setSelectedSuitcases([]);
  };

  const currentUserID = store!.user!.id;

  // Draft suitcase
  const draftSuitcase = suitcase.projects.filter((sc) => sc.private);

  // Private suitcases
  const allPrivateSuitcases = suitcase.projects.filter(
    (project) => project.user_id === currentUserID && !project.is_shared && !project.private,
  );

  const rootPrivateSuitcases = allPrivateSuitcases.filter(applySearchFilter(searchInput)).sort((a, b) => a.name.localeCompare(b.name));

  // Shared suitcases
  const allSharedSuitcases = suitcase.projects.filter((project) => project.is_shared);

  const rootSharedSuitcases = allSharedSuitcases.filter(applySearchFilter(searchInput)).sort((a, b) => a.name.localeCompare(b.name));

  // Pre-packed suitcases
  const prepackedSuitcases = suitcase.prepackedprojects.sort((a, b) => a.name.localeCompare(b.name)).filter(applySearchFilter(searchInput));

  const getSuitcaseLayoutComponent = (layout) => (layout === "list" ? SuitcasesList : SuitcasesGrid);

  const suitcaseClickCallback = () => getMixpanel(store!).track("Suitcases > Click Suitcase", { "Search Term": searchInput });

  const NoResultsMessage = ({ display }) =>
    display ? (
      <div className="text-muted pl-3 fs-1125">No suitcases to display {searchInput ? `for search term "${searchInput}"` : ""}</div>
    ) : null;

  const suitcasesTabItems = [
    {
      menuItem: (
        <StyledMenuItem
          key="private"
          role="tab"
          id="suitcasesPrivateTab"
          aria-selected={suitcase.activeSuitcasesTab === 0}
          aria-controls="suitcasesPrivateTabPanel"
        >
          <Popup
            trigger={
              <div>
                Private
                <Icon name="lock" className="ml-2 d-none d-sm-inline" />
              </div>
            }
            content="Suitcases viewable only by you"
            position="top center"
            size="mini"
            inverted
          />
        </StyledMenuItem>
      ),
      render: (props) => {
        const SuitcaseLayout = getSuitcaseLayoutComponent(props.layout);
        return (
          <div role="tabpanel" id="suitcasesPrivateTabPanel" aria-labelledby="suitcasesPrivateTab">
            <SuitcaseLayout
              suitcases={rootPrivateSuitcases}
              draft={draftSuitcase}
              showFolder={true}
              suitcaseClickCallback={suitcaseClickCallback}
              showCheckboxes={showCheckboxes}
              selectSuitcase={selectSuitcase}
              deselectSuitcase={deselectSuitcase}
            />
            <NoResultsMessage display={!rootPrivateSuitcases.length} />
          </div>
        );
      },
    },
    {
      menuItem: (
        <StyledMenuItem
          key="shared"
          role="tab"
          id="suitcasesSharedTab"
          aria-selected={suitcase.activeSuitcasesTab === 1}
          aria-controls="suitcasesSharedTabPanel"
        >
          <Popup
            trigger={
              <div>
                Shared
                <Icon name="lock open" className="ml-2 d-none d-sm-inline" />
              </div>
            }
            content="Suitcases you share or are shared with you"
            position="top center"
            size="mini"
            inverted
          />
        </StyledMenuItem>
      ),
      render: (props) => {
        const SuitcaseLayout = getSuitcaseLayoutComponent(props.layout);
        return (
          <div role="tabpanel" id="suitcasesSharedTabPanel" aria-labelledby="suitcasesSharedTab">
            {rootSharedSuitcases.length ? (
              <SuitcaseLayout
                suitcases={rootSharedSuitcases}
                showFolder={true}
                showAccess={true}
                suitcaseClickCallback={suitcaseClickCallback}
                showCheckboxes={showCheckboxes}
                selectSuitcase={selectSuitcase}
                deselectSuitcase={deselectSuitcase}
              />
            ) : (
              <EmptyStateSecondary
                heading="No Shared Suitcases to Show"
                description={
                  <ListPageEmptyStateDescription
                    description="Here is where you will find suitcases you have shared and Suitcases shared with you."
                    link="https://knowledge.seerdata.ai/how-do-i-share-a-suitcase"
                    text="shared Suitcases"
                  />
                }
              />
            )}
          </div>
        );
      },
    },
    {
      menuItem: (
        <StyledMenuItem
          key="pre-packed"
          role="tab"
          id="suitcasesPrepackedTab"
          aria-selected={suitcase.activeSuitcasesTab === 2}
          aria-controls="suitcasesPrepackedTabPanel"
        >
          <Popup
            trigger={
              <div>
                Pre-packed
                <Icon name={"suitcase rolling" as SemanticICONS} className="ml-2 d-none d-sm-inline" />
              </div>
            }
            content="Created by the Seer Data team, viewable by everyone"
            position="top center"
            size="mini"
            inverted
          />
        </StyledMenuItem>
      ),
      render: (props) => {
        const SuitcaseLayout = getSuitcaseLayoutComponent(props.layout);
        return (
          <div role="tabpanel" id="suitcasesPrepackedTabPanel" aria-labelledby="suitcasesPrepackedTab">
            <SuitcaseLayout suitcases={prepackedSuitcases} showFolder={true} suitcaseClickCallback={suitcaseClickCallback} />
            <NoResultsMessage display={!prepackedSuitcases.length} />
          </div>
        );
      },
    },
  ];

  const selectSuitcase = (suitcaseId) => {
    setSelectedSuitcases([...selectedSuitcases, suitcaseId]);
  };

  const deselectSuitcase = (suitcaseId) => {
    const index = selectedSuitcases.findIndex((s) => s === suitcaseId);
    const newSuitcaseArray = [...selectedSuitcases];
    if (index >= 0) {
      newSuitcaseArray.splice(index, 1);
      setSelectedSuitcases(newSuitcaseArray);
    }
  };

  // For MoveAndDuplicateModal
  const suitcasesToBeMoved = [[...rootPrivateSuitcases], [...rootSharedSuitcases], [...prepackedSuitcases]][
    suitcase.activeSuitcasesTab
  ].filter((suitcase) => selectedSuitcases.some((id) => id === suitcase.id));

  return (
    <StyledContainer>
      {suitcase.loading ? <ShowLoaderGlobal /> : null}
      {/* Heading */}
      <div className="mt-4 mb-4 d-sm-flex align-items-center justify-content-between">
        <StyleHeading>Suitcases</StyleHeading>
        <div>
          {showCheckboxes ? (
            <>
              <Button onClick={() => setShowCheckboxes(false)}>Cancel</Button>
              <Button
                color="purple"
                className="mr-3"
                onClick={() => setShowMoveSuitcaseModal(true)}
                disabled={selectedSuitcases.length === 0}
              >
                Move
              </Button>
            </>
          ) : (
            <>
              <Button color="red" onClick={createSuitcase}>
                Create suitcase <Icon className="mr-0 ml-1" name="add circle" />
              </Button>
              {(userPlan === "Plus" || userPlan === "Unlimited") &&
                [...rootPrivateSuitcases, ...rootSharedSuitcases].some((s) => ["owner", "write"].includes(s.user_access || "")) && (
                  <Dropdown
                    className="ml-3"
                    icon="ellipsis vertical"
                    text=" "
                    options={[{ key: 1, text: "Bulk Edit", value: 1, onClick: () => setShowCheckboxes(true) }]}
                    simple
                    item
                  />
                )}
            </>
          )}
        </div>
      </div>

      {/* Move suitcase modal */}
      <MoveDuplicateModal
        type="suitcase"
        isOpen={showMoveSuitcaseModal}
        closeModal={() => setShowMoveSuitcaseModal(false)}
        actionClickHandler={bulkMoveSuitcasesAction}
        itemsToBeActioned={suitcasesToBeMoved}
        heading={`Move ${selectedSuitcases.length} Suitcase${selectedSuitcases.length > 1 ? "s" : ""}`}
        actionText="Move"
      />

      {/* Suitcases Tabs */}
      <div className="position-relative">
        {/* Layout toggle */}
        <StyledControlsFilters className="d-flex">
          <Input
            name="suitcases_filter_search"
            role="search"
            aria-label="Suitcases Filter"
            className="mr-2"
            size="mini"
            placeholder="Filter suitcases..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value || "")}
            onBlur={() => getMixpanel(store!).track("Suitcases > Search > End", { "Search Term": searchInput })}
            onFocus={() => getMixpanel(store!).track("Suitcases > Search > Start")}
            icon={<Icon name={searchInput ? "close" : "filter"} link={!!searchInput} onClick={() => searchInput && setSearchInput("")} />}
          />
          <Button.Group role="radiogroup" aria-label="Suitcases Layout">
            {layoutOptions.map((layoutOption, idx) => (
              <Popup
                content={layoutOption.value === "list" ? "List view" : "Grid view"}
                trigger={
                  <Button
                    role="radio"
                    aria-checked={layout === layoutOption.value}
                    aria-label={`${layoutOption.value} view`}
                    key={idx}
                    icon={layoutOption.icon}
                    style={layout === layoutOption.value ? { color: "darkslategrey" } : { color: "lightslategrey" }}
                    active={layout === layoutOption.value}
                    onClick={() => changeLayout(layoutOption.value)}
                  />
                }
                position="top center"
                size="mini"
                inverted
              />
            ))}
          </Button.Group>
        </StyledControlsFilters>
        {/* Tab Content */}
        <StyleTab
          menu={{ secondary: true, pointing: true, role: "tablist", "aria-label": "Suitcase Type" }}
          activeIndex={suitcase.activeSuitcasesTab}
          onTabChange={handleTabChange}
          layout={layout}
          panes={suitcasesTabItems}
        />
      </div>
    </StyledContainer>
  );
};

export const SuitcasesContent = inject("store")(observer(SuitcasesContentComponent));
