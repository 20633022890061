import * as React from "react";
import styled from "styled-components";
import { Button, ButtonProps, Dropdown, Icon, Popup, SemanticICONS } from "semantic-ui-react";
import { COLORS } from "component/UI/common";
import { CollaboratorDropdown, CollaboratorDropdownListContainer } from "./Collaborate.style";
import { Avatar } from "component/Avatar";

export const StylePillButton = styled(Button)<{ active?: boolean }>`
  font-family: "Open Sans" !important;
  color: ${(props) => (props.active ? "#ffffff" : COLORS.indigo600)} !important;
  border: 1px solid ${COLORS.indigo600} !important;
  background: ${(props) => (props.active ? COLORS.indigo600 : "none")} !important;
  border-radius: 20px !important;
  transition: 0.5s !important;
  width: "fit-content";
`;

const IconButtonWrapper = styled.div<{ disabled: boolean }>`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  :hover {
    background-color: ${(props) => (props.disabled ? "none" : COLORS.indigo100)};
  }
`;

const StyledIcon = styled(Icon)<{ iconColor: string }>`
  margin-left: 4px !important;
  color: ${(props) => props.iconColor};
`;

interface IconButtonProps extends ButtonProps {
  onClick?: any;
  icon: SemanticICONS;
  iconColor?: string;
}

export const IconButton = (props: IconButtonProps): JSX.Element => {
  const { onClick, icon, iconColor = COLORS.grey600, ...rest } = props;

  return (
    <IconButtonWrapper onClick={onClick} disabled={!onClick} {...rest}>
      <StyledIcon name={icon} iconColor={iconColor} size="small" />
    </IconButtonWrapper>
  );
};

interface ColorConfig {
  fg: string;
  bg: string;
}

const PILL_BUTTON_COLORS: Record<string, ColorConfig> = {
  disabled: {
    fg: COLORS.grey400,
    bg: "#ffffff",
  },
  red: {
    fg: COLORS.red500,
    bg: COLORS.red100,
  },
  aqua: {
    fg: COLORS.aqua600,
    bg: COLORS.aqua200,
  },
};

interface PillButtonProps {
  size: "small" | "large";
  disabled?: boolean;
  color: "red" | "aqua";
  label: string;
  onClick: any;
  className?: string;
}

const getPillButtonColors = (props: PillButtonProps): ColorConfig => {
  if (props.disabled) {
    return PILL_BUTTON_COLORS["disabled"];
  } else {
    return PILL_BUTTON_COLORS[props.color];
  }
};

const PillButtonContainer = styled.div<{ buttonProps: PillButtonProps }>`
  padding: 4px 8px;
  border-radius: 50px;
  cursor: ${(props) => (props.buttonProps.disabled ? "default" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  :hover {
    background-color: ${(props) => getPillButtonColors(props.buttonProps as PillButtonProps).bg};
  }
  p {
    color: ${(props) => getPillButtonColors(props.buttonProps as PillButtonProps).fg};
    font-family: "Open Sans";
    font-size: ${(props) => (props.buttonProps.size === "small" ? "12" : "14")}px;
    font-weight: 600;
  }
`;

export const PillButton = (props: PillButtonProps): JSX.Element => {
  const { label, className, onClick } = props;
  return (
    <PillButtonContainer buttonProps={props} onClick={onClick} className={className}>
      <p>{label}</p>
    </PillButtonContainer>
  );
};

export type FilterOption = "all" | "unresolved" | "resolved";

interface PillFilterProps {
  activeFilter: FilterOption;
  filters: { label: string; value: FilterOption }[];
  onSelect: (value: FilterOption) => void;
}

export const PillFilter = (props: PillFilterProps): JSX.Element => {
  const { activeFilter, filters, onSelect } = props;
  return (
    <div>
      {filters.map((filter) => {
        const { label, value } = filter;
        return (
          <StylePillButton
            key={value}
            size="mini"
            active={activeFilter === value}
            className="cursor-pointer mr-2 mb-2 mb-md-0"
            onClick={() => onSelect(value)}
          >
            {label}
          </StylePillButton>
        );
      })}
    </div>
  );
};

interface CollaborateTopSectionProps {
  activeFilter: FilterOption;
  filters: { label: string; value: FilterOption }[];
  onSelect: (value: FilterOption) => void;
  fullscreen: boolean;
  dashboardID: number;
  collaborators: any[];
  onClose: () => void;
}

export const CollaborateTopSection = (props: CollaborateTopSectionProps): JSX.Element => {
  const { activeFilter, filters, onSelect, fullscreen, dashboardID, collaborators, onClose } = props;
  return (
    <div className="d-flex align-items-center justify-content-between mb-4">
      <PillFilter activeFilter={activeFilter} filters={filters} onSelect={(value) => onSelect(value)} />
      <div className="d-flex align-items-center">
        <div className="mr-1" style={{ width: 1, height: 20, backgroundColor: COLORS.grey300 }}></div>
        <div className="mr-1">
          <Dropdown
            openOnFocus
            direction="left"
            icon={<></>}
            className="d-block"
            trigger={<Icon name="user circle outline" className="mr-0 fs-1000" size="small" style={{ color: COLORS.grey800 }} />}
          >
            <CollaboratorDropdown>
              <p className="fs-1000 fw-700 ff-primary m-2" style={{ color: COLORS.grey900 }}>{`Members ${collaborators.length}`}</p>
              <div className="my-1" style={{ height: 1, width: "100%", backgroundColor: COLORS.grey400 }}></div>
              <CollaboratorDropdownListContainer>
                {collaborators?.map((collaborator) => {
                  const { id, avatar, first_name, last_name } = collaborator;
                  return (
                    <div className="d-flex align-items-center py-2" key={id}>
                      <Avatar
                        className="cursor-pointer mr-2"
                        color={COLORS.indigo500}
                        image={avatar}
                        name={`${first_name} ${last_name}`}
                        size={25}
                      />
                      <p className="fs-1000 fw-400 ff-primary" style={{ color: COLORS.grey800 }}>{`${first_name} ${last_name}`}</p>
                    </div>
                  );
                })}
              </CollaboratorDropdownListContainer>
            </CollaboratorDropdown>
          </Dropdown>
        </div>
        {!fullscreen && (
          <Popup
            trigger={
              <IconButton
                onClick={() => {
                  onClose();
                  window.open(`/dashboards/collaborate/${dashboardID}?fullscreen=true`, `${dashboardID}`);
                }}
                iconColor={COLORS.grey600}
                icon="external alternate"
              />
            }
            content="Open in New Tab"
            position="top right"
            size="mini"
            inverted
          />
        )}
      </div>
    </div>
  );
};
