import styled from "styled-components";
import { COLORS } from "component/UI/common";

export const StyledTabActions = styled.div`
  @media (min-width: 768px) {
    position: absolute;
    top: 10px;
    right: 0;
  }
`;

const dashboardSelectionStyling = `0px 0px 0px 2px ${COLORS.aqua500}, 1px 1px 5px 2px rgba(5, 54, 61, 0.2), -1px -1px 5px 2px rgba(5, 54, 61, 0.2)`;

export const DashboardWrapper = styled.div<{ selectedContent?: string; selectedRow?: string; selectedColumn?: string }>`
  flex-grow: 1;
  &.select-overlay {
    // div[id^="u_row"]:hover {
    //   box-shadow: ${dashboardSelectionStyling};
    // }
    // div[id^="u_column"]:hover {
    //   box-shadow: ${dashboardSelectionStyling};
    // }
    div[id^="u_content"]:hover {
      box-shadow: ${dashboardSelectionStyling};
    }
    div[id^="u_content"] > * {
      pointer-events: none; // prevent click events from being captured on clickable content while selection in progress
    }
    ${(props) =>
      `div#${props.selectedRow} {
          box-shadow: ${dashboardSelectionStyling};
        }
        div#${props.selectedColumn} {
          box-shadow: ${dashboardSelectionStyling};
        }
        div#${props.selectedContent} {
          box-shadow: ${dashboardSelectionStyling};
        }`}
  }
  &.highlight-overlay {
    ${(props) =>
      `div#${props.selectedRow} {
        box-shadow: ${dashboardSelectionStyling};
      }
      div#${props.selectedColumn} {
        box-shadow: ${dashboardSelectionStyling};
      }
      div#${props.selectedContent} {
        box-shadow: ${dashboardSelectionStyling};
      }`}
  }
`;
